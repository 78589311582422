import React from 'react'
import CustomBreadcrumbs from 'components/CustomBreadcrumbs';

const ComorbidadesBreadcrumbs = () => (
  <CustomBreadcrumbs
    links={[
      { label: 'Meus pacientes', route: '/meus-pacientes' },
      { label: 'Categorias', route: '/categorias' },
      {
        label: 'Informações sobre Qualidade de Vida',
        route: '/categorias/info-qualidade-vida',
      },
    ]}
  />
)

export default ComorbidadesBreadcrumbs
