import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import CustomBreadcrumbs from 'components/CustomBreadcrumbs';
import { customBreadcrumbsLinks } from './statics';
import PatientInfo from 'components/PatientInfo';
import { useToast } from 'hooks/toast';
import { usePatient } from 'context/PatientContext';
import { Formik, Form, Field } from 'formik';
import {
    FormControl,
    Typography,
    Grid,
    FormGroup,
    FormLabel,
    TextField,
    Card,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
} from '@material-ui/core';
import { buscarHistoricoPaciente } from 'services/requests/datasRequests';

const GynecologicalHistory = () => {
    const classes = useStyles();

    const history = useHistory();

    const { addToast } = useToast();

    // const [initialValues, setinItialValues] = useState({
    //     nome_completo: '',
    // });

    const { patient } = usePatient();

    const [loading, setLoading] = useState(false);

    const [patientHistory, setPatientHistory] = useState({});

    const formRef = useRef(null);

    const handleInfos = useCallback(async () => {
        setLoading(true);
        try {
            const responseHistory = await buscarHistoricoPaciente(patient.id);
            setPatientHistory(responseHistory);
        } catch (err) {
            if (err.response.status === 404) {
                setPatientHistory(null);
                return null;
            }
            addToast({
                type: 'error',
                message: 'Erro ao tentar carregar informações, tente novamente',
            });
            history.goBack();
        } finally {
            setLoading(false);
        }
    }, [addToast, history, patient.id]);

    useEffect(() => {
        handleInfos();
    }, [handleInfos]);

    const handleSubmit = () => {
        formRef.current.submit();
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <CustomBreadcrumbs links={customBreadcrumbsLinks} />
            </div>

            <div className={classes.formWrapper}>
                <Formik
                    enableReinitialize
                    // initialValues={initialValues}
                    // onSubmit={handleSubmit}
                    validateOnMount
                // validationSchema={schema}
                >
                    {({
                        values,
                        touched,
                        handleChange,
                        isSubmitting,
                        errors,
                        setFieldValue,
                    }) => (
                        <Form component={FormControl}>
                            <div>
                                <div className={classes.titleWrapper}>
                                    <div className={classes.rightContent}>
                                        <PatientInfo />

                                        <Button
                                            className={classes.buttonSave}
                                            color="secondary"
                                            disabled={!!patientHistory}
                                            onClick={handleSubmit}
                                            type="submit"
                                            variant="contained"
                                        >
                                            Salvar
                                        </Button>
                                    </div>
                                </div>

                                <div className={classes.titleWrapper}>
                                    <Typography variant="h3">História Ginecológica</Typography>
                                </div>
                                <Grid
                                    component={Card}
                                    container
                                    item
                                    lg={12}
                                    spacing={2}
                                >
                                    <Grid item md={4} sm={12}>
                                        <FormGroup>
                                            <FormLabel>
                                                <Typography variant="h4">Idade da primeira menstruação</Typography>
                                            </FormLabel>
                                            <Field
                                                as={TextField}
                                                className={classes.textField}
                                                // error={errors.nome_completo && touched.nome_completo}
                                                // helperText={
                                                //     errors.nome_completo && touched.nome_completo
                                                //         ? errors.nome_completo
                                                //         : null
                                                // }
                                                label="Idade da primeira menstruação"
                                                // name="idade_primeira_menstruacao"
                                                type="number"
                                                // value={values.nome_completo}
                                                variant="outlined"
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item md={4} sm={12}>
                                        <FormGroup>
                                            <FormLabel>
                                                <Typography variant="h4">Ciclo menstrual</Typography>
                                            </FormLabel>
                                            <Field
                                                as={TextField}
                                                className={classes.textField}
                                                // error={errors.sexo && touched.sexo}
                                                // helperText={
                                                //     errors.sexo && touched.sexo ? errors.sexo : null
                                                // }
                                                label="Ciclo menstrual"
                                                // name="sexo"
                                                // onChange={handleChange}
                                                select
                                                type="text"
                                                // value={values.sexo}
                                                variant="outlined"
                                            >
                                                <MenuItem
                                                    disabled
                                                    value={'0'}
                                                >
                                                    Selecione um ciclo menstrual...
                                                </MenuItem>
                                                <MenuItem value={'normal'}>Normal - 21 a 35 dias</MenuItem>
                                            </Field>
                                        </FormGroup>
                                    </Grid>

                                    <Grid item md={4} sm={12}>
                                        <FormGroup>
                                            <FormLabel>
                                                <Typography variant="h4">Sintomas de endometriose</Typography>
                                            </FormLabel>
                                            <Field
                                                as={TextField}
                                                className={classes.textField}
                                                // error={errors.sexo && touched.sexo}
                                                // helperText={
                                                //     errors.sexo && touched.sexo ? errors.sexo : null
                                                // }
                                                label="Sintomas de endometriose"
                                                // name="sexo"
                                                // onChange={handleChange}
                                                select
                                                type="text"
                                                // value={values.sexo}
                                                variant="outlined"
                                            >
                                                <MenuItem
                                                    disabled
                                                    value={'0'}
                                                >
                                                    Selecione um sintoma...
                                                </MenuItem>
                                                <MenuItem value={'dor_pelvica'}>Dor pélvica</MenuItem>
                                                <MenuItem value={'sangramento_irregular'}>Sangramento irregular</MenuItem>
                                            </Field>
                                        </FormGroup>
                                    </Grid>

                                    <Grid item md={12} sm={12}>
                                        <FormGroup>
                                            <FormLabel>
                                                <Typography variant="h4">Histórico de gravidez e partos</Typography>
                                            </FormLabel>
                                            <TextField
                                                className={classes.textField}
                                                // error={errors.nome_completo && touched.nome_completo}
                                                // helperText={
                                                //   errors.nome_completo && touched.nome_completo
                                                //     ? errors.nome_completo
                                                //     : null
                                                // }
                                                placeholder="Descreva o histórico de gravidez e partos da paciente"
                                                multiline
                                                rows={4}
                                                maxRows={4}
                                                label="Histórico de gravidez e partos"
                                                // name="outros"
                                                type="textarea"
                                                // value={values.nome_completo}
                                                variant="outlined"
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item md={12} sm={12}>
                                        <FormGroup>
                                            <FormLabel>
                                                <Typography variant="h4">Histórico de contracepção</Typography>
                                            </FormLabel>
                                            <TextField
                                                className={classes.textField}
                                                // error={errors.nome_completo && touched.nome_completo}
                                                // helperText={
                                                //   errors.nome_completo && touched.nome_completo
                                                //     ? errors.nome_completo
                                                //     : null
                                                // }
                                                placeholder="Descreva o histórico de contracepção da paciente"
                                                multiline
                                                rows={4}
                                                maxRows={4}
                                                label="Histórico de contracepção"
                                                // name="outros"
                                                type="textarea"
                                                // value={values.nome_completo}
                                                variant="outlined"
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default GynecologicalHistory;
