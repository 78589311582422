import React from 'react';
import { CustomBreadcrumbs } from 'components';

const DailyEvolutionBreadcrumbs = (props) => {
  const { id } = props;
  return (
    <CustomBreadcrumbs
      links={[
        { label: 'Meus pacientes', route: '/meus-pacientes' },
        { label: 'Categorias', route: '/categorias' },
        {
          label: 'Tratamentos anteriores',
          route: '/categorias/tratamentos-anteriores',
        },
        {
          label: 'Tratamento anterior',
          route: `/categorias/tratamento-anterior`,
        },
      ]}
    />
  );
}

export default DailyEvolutionBreadcrumbs;
