import React from 'react';
import { CustomBreadcrumbs } from 'components';

const DailyEvolutionListBreadcrumbs = () => {
  return (
    <CustomBreadcrumbs
      links={[
        { label: 'Meus pacientes', route: '/meus-pacientes' },
        { label: 'Categorias', route: '/categorias' },
        {
          label: 'Tratamentos anteriores',
          route: '/categorias/tratamentos-anteriores',
        },
      ]}
    />
  );
}

export default DailyEvolutionListBreadcrumbs;
